import { AbortError, exponentialBackOff, retry } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import {
  SendAuthenticatedRequestParams,
  Token,
  TokenProvider,
  sendAuthenticatedRequest,
} from "../authentication";
import { clientIdHeader } from "../utils/headers";
import {
  GetAllUserProjectsParams,
  GetUserProjectsPageParams,
} from "./core-api-client-parameters";
import { CoreApiError } from "./core-api-errors";
import {
  ChunkUploadDescription,
  CompaniesPayload,
  ProjectContextPayload,
  ProjectDescriptor,
  ProjectInfoPayload,
  ProjectMembersPayload,
  ProjectUsersPayload,
  ProjectsPayload,
  UnknownPayload,
  UploadRequestDescription,
  UserDetailsPayload,
  UserTokensPayload,
  WorkspacesPayload,
  isChunkUploadPayload,
  isCompaniesPayload,
  isCoreApiResponse,
  isErrorMessage,
  isProjectContextPayload,
  isProjectInfoPayload,
  isProjectMembersPayload,
  isProjectUsersPayload,
  isProjectsPayload,
  isSphereLinkTokenPayload,
  isTokenRequestPayload,
  isUploadRequestPayload,
  isUserDetailsPayload,
  isUserTokenResponse,
  isWorkspacesPayload,
} from "./core-api-responses";

/**
 * A class to query the HoloBuilder CoreApi
 *
 * Api Docs at https://core.api.holobuilder.com/docs
 */
export class CoreApiClient {
  #sessionProvider?: TokenProvider;
  #onAuthenticationError?: () => void;

  /**
   * Create a new CoreApi client
   *
   * @param endpoint The URL for the CoreApi instance to use
   * @param clientId A string representation of the client in the format client/version
   * @param sessionProvider A function to obtain a session cookie token (optional).
   *                        If not provided, the client will use the already existing cookies for authentication.
   * @param onAuthenticationError A callback function which will be executed on an authentication error
   */
  constructor(
    private endpoint: string,
    private clientId?: string,
    sessionProvider?: TokenProvider,
    onAuthenticationError?: () => void,
  ) {
    this.#sessionProvider = sessionProvider;
    this.#onAuthenticationError = onAuthenticationError;
  }

  /** @returns the base url for this CoreApi instance */
  get baseUrl(): string {
    return this.endpoint;
  }

  /**
   * Sets the session provider
   *
   * @param provider The callback function to retrieve the session token
   */
  set sessionProvider(provider: TokenProvider) {
    this.#sessionProvider = provider;
  }

  /**
   * URL to use to start the CoreApi<->Sphere backend authorization
   *
   * The user need to authorize the CoreApi to talk to the Sphere backends.
   *
   * The flow to authorize is to open a child tab/popup of the HB authenticated page
   * to the URL returned by this function
   *
   * This new window will then ask the user to login to Sphere and authorize the connection.
   * Then the window will auto close and a message is posted to the main window with the result of the operation
   *
   * Opening this URL directly will not work as it will miss the required HB authentication
   * Opening this URL in a frame will return an error as the page forbid to be used as an iframe
   *
   * @returns The URL to open a secondary window/tab to authorize CoreApi to talk to the Sphere Backend
   */
  sphereAuthorizationUrl(): string {
    return `${this.endpoint}/v1/users/tokens/connect?providerId=faro-sphere`;
  }

  /**
   * @returns true if the CoreApi has a valid token to talk to Sphere
   */
  async hasValidSphereAuthorization(): Promise<boolean> {
    const response = await this.getUsersTokens();

    const now = Date.now();

    return response.data.some(
      (token) =>
        token.provider === "faro-sphere" &&
        (token.expiration === undefined || token.expiration > now),
    );
  }

  /**
   * Request a Token that can be used to link a project to Sphere through the ProjectApi
   *
   * @param projectId of the project we want to link to sphere
   * @returns the token to use to connect to the sphere backend
   */
  async getSphereLinkToken(projectId: GUID): Promise<Token> {
    const response = await this.#fetch("v3/auth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        scopes: ["user:project", "user:integrations", "user:faro-sphere"],
        data: {
          projects: [{ id: projectId, permissions: ["connect"] }],
        },
      }),
    });

    if (!isSphereLinkTokenPayload(response)) {
      throw new Error("CoreApi returned an invalid SphereLinkToken");
    }

    return response.data.token;
  }

  /**
   * Query the information about a specific project
   * - If the project is public/unlisted, this route will still return the project info
   * - It will fail of the project is set to private
   *
   * NOTE: This query for now just return the sub-set of the query info we need in the sphere-viewer
   * - project name
   * - current user permissions
   * A lot more information seem available from the server response but as the swagger is missing
   * most of the details to reduce the risk of using the data in the wrong way only the minimum
   * is currently used.
   * See: https://core.api.dev.holobuilder.eu/docs#/V2/getProject_2
   *
   * @param projectId Id of the project
   * @returns the project information like the name and the user permissions
   */
  async getProjectInfo(projectId: GUID): Promise<ProjectInfoPayload["data"]> {
    const response = await this.#fetch(`v2/projects/player/${projectId}`);

    if (!isProjectInfoPayload(response)) {
      throw new Error("CoreApi returned an invalid ProjectInfo payload");
    }

    return response.data;
  }

  /**
   * @returns information about the project "in context" of the current user.
   * E.g. Project-level permission-roles that take into account the user's role.
   * @param projectId The project to get the project context for
   */
  async getProjectContext(
    projectId: GUID,
  ): Promise<ProjectContextPayload["data"]> {
    const response = await this.#fetch(`v3/projects/${projectId}/context`);

    if (!isProjectContextPayload(response)) {
      throw new Error("CoreApi returned an invalid ProjectContext payload");
    }

    return response.data;
  }

  /**
   * @returns the users' tokens that shows which integration is available or not
   */
  async getUsersTokens(): Promise<UserTokensPayload> {
    const response = await this.#fetch("v1/users/tokens");
    if (!isUserTokenResponse(response)) {
      throw new Error("CoreApi returned an invalid UserTokens payload");
    }
    return response;
  }

  /**
   * Ask the CoreApi for the url to upload a small file (without chunks)
   *
   * @param projectId id of the project that will use this file
   * @param contentType MIME type of the file
   * @param downloadName name of the file to use when it's downloaded again
   * @param token bearer token for this user to edit the target project {@see getUserProjectToken}
   * @returns a structure with all the data needed to upload the file
   */
  async getUploadSignedUrl(
    projectId: GUID,
    contentType: string,
    downloadName: string,
    token: string,
  ): Promise<UploadRequestDescription> {
    const params = new URLSearchParams({
      contentType,
      projectId,
      downloadName,
    });

    const response = await this.#fetch(
      `v3/files/signedUrl?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!isUploadRequestPayload(response)) {
      throw new Error("CoreApi returned an invalid UploadPayload");
    }
    return response.data;
  }

  /**
   * Uploads a file with a single request to the backend, it should only be used for small files that fit in one chunk.
   * Use the core-file-uploader.ts for bigger files, as it splits the upload in multiple chunks.
   *
   * @param file to upload
   * @param projectId project to upload the file to
   * @param signal to abort the upload
   * @returns the url at which the file can be downloaded
   */
  async uploadSmallFile(
    file: File,
    projectId: GUID,
    signal?: AbortSignal,
  ): Promise<string> {
    const taskName = "Small File Upload";
    const bearerToken = await this.getUserProjectToken(projectId);
    if (signal?.aborted) {
      throw new AbortError(taskName);
    }
    const uploadData = await this.getUploadSignedUrl(
      projectId,
      file.type,
      file.name,
      bearerToken,
    );
    if (signal?.aborted) {
      throw new AbortError(taskName);
    }

    const blobData = await file.arrayBuffer();
    const request = await retry(
      () =>
        fetch(uploadData.uploadUrl, {
          method: uploadData.method,
          body: blobData,
          headers: {
            ...uploadData.headers,
          },
          signal,
        }),
      {
        max: 5,
        delay: exponentialBackOff,
      },
    );

    if (signal?.aborted) {
      throw new AbortError(taskName);
    }
    if (!request.ok) {
      throw new Error(
        `Upload Request failed: ${request.status} ${request.statusText}`,
      );
    }
    return uploadData.downloadUrl;
  }

  /**
   * Ask the CoreApi for the data to upload a big files in chunks
   *
   * @param projectId id of the project that will use this file
   * @param contentType MIME type of the file
   * @param downloadName name of the file to use when it's downloaded again
   * @param length in bytes of the file to upload
   * @param token bearer token for this user to edit the target project {@see getUserProjectToken}
   * @returns a structure with all the data needed to upload the file
   */
  async getChunkUploadData(
    projectId: GUID,
    contentType: string,
    downloadName: string,
    length: number,
    token: string,
  ): Promise<ChunkUploadDescription> {
    const params = new URLSearchParams({
      contentType,
      projectId,
      length: `${length}`,
      downloadName,
    });

    const response = await this.#fetch(
      `v3/files/signedUrl/chunked?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!isChunkUploadPayload(response)) {
      throw new Error("CoreApi returned an invalid ChunkUploadPayload");
    }
    return response.data;
  }

  /**
   * Request a token for authenticated calls against a project
   *
   * @param projectId id of the project
   * @returns the Bearer token to use for follow-up authenticated calls
   */
  async getUserProjectToken(projectId: GUID): Promise<string> {
    const response = await this.#fetch("v3/auth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          projects: [{ id: projectId }],
        },
        // Scope to use in order to provide a user access to a project
        scopes: ["user:project"],
      }),
    });

    if (!isTokenRequestPayload(response)) {
      throw new Error("CoreApi returned an invalid TokenRequestPayload");
    }

    return response.data.token;
  }

  /**
   * @returns the logged in user details
   */
  async getLoggedInUser(): Promise<UserDetailsPayload> {
    const response = await this.#fetch("v1/users/getLoggedInUser");

    if (!isUserDetailsPayload(response)) {
      throw new Error("CoreApi returned an invalid UserDetails payload");
    }
    return response;
  }

  /**
   * @returns The list of information of the users that have access to the project
   * @param companyId The unique id of the company
   * @param projectId The unique id of the project
   */
  async getProjectMembers(
    companyId: GUID,
    projectId: GUID,
  ): Promise<ProjectMembersPayload> {
    const response = await this.#fetch(
      `/v3/dashboard/company/${companyId}/project/${projectId}/member`,
    );
    if (!isProjectMembersPayload(response)) {
      throw new Error("CoreApi returned an invalid UserDetails payload");
    }
    return response;
  }

  /**
   * @returns The list of information of the users that have access to the project
   * @param projectId The unique id of the project
   */
  async getProjectUsers(projectId: GUID): Promise<ProjectUsersPayload> {
    const response = await this.#fetch(`v2/projects/${projectId}/permission`);
    if (!isProjectUsersPayload(response)) {
      throw new Error("CoreApi returned an invalid UserDetails payload");
    }
    return response;
  }

  /**
   * @returns the workspaces the current user has access to
   */
  async getUserWorkspaces(): Promise<WorkspacesPayload> {
    const response = await this.#fetch("v3/users/me/workspaces");

    if (!isWorkspacesPayload(response)) {
      throw new Error("CoreApi returned an invalid Workspaces payload");
    }

    return response;
  }

  /**
   * @returns the companies the user is a member of ( has access to )
   */
  async getUserCompanies(): Promise<CompaniesPayload> {
    const response = await this.#fetch("v3/company");

    if (!isCompaniesPayload(response)) {
      throw new Error("CoreApi returned an invalid Company payload");
    }

    return response;
  }

  /**
   * Returns projects the current user has access to, filtered by the given parameters.
   * The api returns a maximum of 30 projects per request.
   *
   * @param params Parameters for the request
   * @param signal AbortSignal to cancel the request
   * @returns ProjectsPayload that contains the filtered projects list
   */
  public getUserProjectsPage = async (
    params?: GetUserProjectsPageParams,
    signal?: AbortSignal,
  ): Promise<ProjectsPayload> => {
    const paramsDict: Record<string, string> = {};

    if (params?.search !== undefined) {
      paramsDict.search = params.search;
    }
    if (params?.companyId !== undefined) {
      paramsDict.companyId = params.companyId;
    }
    if (params?.groupId !== undefined) {
      paramsDict.groupId = params.groupId;
    }
    if (params?.archivingState !== undefined) {
      paramsDict.archivingStates = params.archivingState;
    }
    if (params?.start !== undefined) {
      paramsDict.start = params.start;
    }

    const response = await this.#fetch("v3/projects", { signal }, paramsDict);

    if (signal?.aborted) {
      throw new AbortError("Fetching User Projects Page");
    }

    if (!isProjectsPayload(response)) {
      throw new Error("CoreApi returned an invalid Projects payload");
    }
    return response;
  };

  /**
   * Returns projects the current user has access to, filtered by the given parameters.
   * Automatically fetches all available pages.
   *
   * @param params Parameters for the request
   * @param signal AbortSignal to cancel the request
   * @returns a filtered projects list with all available pages
   */
  public getAllUserProjects = async (
    params?: GetAllUserProjectsParams,
    signal?: AbortSignal,
  ): Promise<ProjectDescriptor[]> => {
    const projects: ProjectDescriptor[] = [];
    let response: ProjectsPayload | undefined = undefined;

    do {
      response = await this.getUserProjectsPage(
        {
          ...params,
          start: response?.next,
        },
        signal,
      );
      projects.push(...response.data);
    } while (response.data.length > 0 && !signal?.aborted);

    if (signal?.aborted) {
      throw new AbortError("Fetching all User Projects");
    }

    return projects;
  };

  /**
   * Allows to authenticate with a email and password, without SSO.
   *
   * @param userId The userId for the user user, usually it's their email
   * @param password The password for the user in plain text
   * @returns user details for the logged in user, like response from getLoggedInUser
   */
  async basicLogin(
    userId: string,
    password: string,
  ): Promise<UserDetailsPayload> {
    const response = await this.#fetch("v1/auth/basicLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams({
        userId,
        password,
      }).toString(),
    });

    if (!isUserDetailsPayload(response)) {
      throw new Error(
        "CoreApi returned an invalid UserDetails payload after login",
      );
    }
    return response;
  }

  /**
   * Requests to logout from the CoreApi
   */
  async logout(): Promise<void> {
    const response = await this.#fetch("v1/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

    if (response.status !== "success") {
      throw new Error(
        "CoreApi returned an error while trying to logout the user",
      );
    }
  }

  /**
   * @returns the header object with the session cookie if a session provider is defined
   */
  private async getSessionCookie(): Promise<HeadersInit> {
    if (!this.#sessionProvider) return {};

    const sessionToken = await this.#sessionProvider();

    return {
      Cookie: `JSESSIONID=${sessionToken}`,
    };
  }

  /**
   * Private fetch implementation that will include the credentials cookies and validate response
   *
   * @param path The CoreApi path to query (Eg. v1/user/tokens)
   * @param init Other request parameters
   * @param queryParams (Optional) query parameters for the request
   * @returns A Promise with the fetch response
   */
  #fetch = async (
    path: string,
    init?: RequestInit,
    queryParams?: SendAuthenticatedRequestParams["queryParams"],
  ): Promise<UnknownPayload> => {
    const req = await sendAuthenticatedRequest({
      path: `${this.endpoint}/${path}`,
      httpMethod: init?.method,
      requestBody: init?.body,
      queryParams,
      credentials: this.#sessionProvider ? "omit" : "include",
      throwOnError: false,
      onAuthenticationError: this.#onAuthenticationError,
      additionalHeaders: {
        Accept: "application/json",
        ...init?.headers,
        ...clientIdHeader(this.clientId),
        ...(await this.getSessionCookie()),
      },
    });

    const response: UnknownPayload = await req.json();

    if (!isCoreApiResponse(response)) {
      throw new Error("CoreApi returned an invalid object");
    }

    if (isErrorMessage(response)) {
      throw new CoreApiError(req.status, req.statusText, response);
    }

    if (!req.ok) {
      // Expected to get a valid error message with an error status
      throw new Error(
        `CoreAPI returned an unexpected status: ${req.statusText}`,
      );
    }

    return response;
  };
}
