import { PropsWithChildren } from "react";
import { FaroButton } from "../button/faro-button";
import { FaroText } from "../text/faro-text/faro-text";
import { TranslateVar } from "../translation";
import { ToolGroup } from "./tool-group";
import { Toolbar } from "./toolbar";

export type ContextualToolbarProps = PropsWithChildren<{
  /**
   * The number of selected elements.
   *
   * If this is 0, the toolbar will be hidden.
   */
  selectedCount: number;

  /** The name of the count to provide to the localization system. */
  translateCountName: string;

  /**
   * The name of the elements that are counted.
   *
   * Provide the singular form here, the pluralization is handled by the localization system.
   */
  elementName: string;

  /** The action to execute when the user clears the selection. */
  clearSelection?(): void;
}>;

/**
 * @returns A toolbar offering contextual actions on the selected elements.
 *
 * It offers:
 * - A label showing the number of selected elements.
 * - Optionally, a set of actions to perform on the selected elements.
 *    This can be provided via the `children` prop, which should be a set of `ToolGroup` components.
 * - A button to clear the selection.
 */
export function ContextualToolbar({
  selectedCount,
  translateCountName,
  elementName,
  clearSelection,
  children,
}: ContextualToolbarProps): JSX.Element | null {
  if (selectedCount === 0) {
    return null;
  }

  return (
    <Toolbar
      direction="row"
      sx={{ alignItems: "center", width: "fit-content" }}
    >
      <ToolGroup sx={{ p: 1.5 }}>
        <FaroText variant="bodyM" dark>
          <TranslateVar name={translateCountName} pluralize={selectedCount}>
            {selectedCount}
          </TranslateVar>{" "}
          {elementName}
          {" selected"}
        </FaroText>
      </ToolGroup>
      {children}
      <ToolGroup>
        <FaroButton
          variant="ghost"
          dark
          sx={{ p: 1.5 }}
          onClick={clearSelection}
        >
          Clear selection
        </FaroButton>
      </ToolGroup>
    </Toolbar>
  );
}
