import {
  assert,
  convertToDateString,
  convertToDateTimeString,
  walkWithQueue,
} from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  IElementBase,
  IElementType,
  IElementTypeHint,
  isIElementGroup,
  isIElementSection,
  isIElementSectionDataSession,
  isIElementTimeseries,
  isIElementTimeseriesDataSession,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import { DataSetAreaInfo } from "@faro-lotv/service-wires";
import { partition } from "es-toolkit";
import { IElementsRecord } from "../i-elements-slice";
import { newestToOldest } from "./i-element-ordering";
import { isIElementVideoModeCopy } from "./i-element-video-mode-copy";

/**
 * Recursive tree structure of the project.
 * Each element contains a small subset of the IElement that is needed for the project tree component.
 */
export type TreeData = {
  /** Unique id of this node in the tree */
  id: string;

  /** The label to show for this item in the tree */
  label: string;

  /** The information about the project element that maps to this tree node */
  element?: Pick<IElementBase, "id" | "type" | "typeHint" | "createdAt">;

  /**
   * All children of this element
   * Recursive part of this data structure
   *
   * If this is `null`, the element is a leaf of the tree
   */
  children: TreeData[] | null;

  /**
   * As some levels of the tree will be removed during tree creation,
   * this will retain type information of the direct tree (from the original structure)
   */
  directParent?: Pick<IElementBase, "type" | "typeHint">;
};

/**
 * @returns true if this element should be hidden if empty
 * @param element the element to check
 * @param parentElement the parent of the element
 */
function shouldHideIfEmpty(
  element: IElement,
  parentElement: IElement,
): boolean {
  return (
    isIElementGroup(element) ||
    isIElementTimeseries(element) ||
    (isIElementSection(element) &&
      element.typeHint === IElementTypeHint.room &&
      parentElement.type !== IElementType.timeSeries)
  );
}

/**
 * @returns true if this element is a data sessions that contains only webshare scans
 * @param element to check
 * @param elements in the project
 */
function isFocusDataSessionWithOnlyScans(
  element: IElement,
  elements: IElementsRecord,
): boolean {
  if (!isIElementSectionDataSession(element)) return false;

  const firstChildId = element.childrenIds?.[0];
  if (element.childrenIds?.length !== 1 || !firstChildId) return false;

  const dataSet = elements[firstChildId];
  if (!dataSet) return false;

  return (
    isIElementWithTypeAndHint(
      dataSet,
      IElementType.section,
      IElementTypeHint.dataSetWs,
    ) &&
    dataSet.childrenIds?.length === 1 &&
    elements[dataSet.childrenIds[0]]?.typeHint === IElementTypeHint.cluster
  );
}

/**
 * This functions check for nodes that should not be visible in the project tree
 *
 * @param element to check
 * @param elements in the project
 * @returns true if this element is a dataset to hide
 */
function isNodeToHide(element: IElement, elements: IElementsRecord): boolean {
  return (
    isFocusDataSessionWithOnlyScans(element, elements) ||
    isIElementWithTypeAndHint(
      element,
      IElementType.section,
      IElementTypeHint.captureTree,
    )
  );
}

/**
 * @returns The tree representation of the whole project
 * @param parentId ID of the parent of the current recursion level
 * @param elements All IElements of the project
 * @param areaDataSets The capture-tree datasets contained in each area
 * @param filterTree true to return the filtered version of the project
 */
export function generateProjectTree(
  parentId: GUID,
  elements: IElementsRecord,
  areaDataSets: Record<GUID, DataSetAreaInfo[] | undefined> = {},
  filterTree = true,
): TreeData[] {
  if (!filterTree) return generateUnfilteredTree(parentId, elements);

  const subtree: TreeData[] = [];

  const parentElement = elements[parentId];
  if (!parentElement) return subtree;

  // The iElements of the current level of recursion
  const levelElementIds = parentElement.childrenIds ?? [];
  createArea4dSessionNodeIfNeeded(
    parentElement,
    areaDataSets,
    elements,
    subtree,
  );

  for (const elementId of levelElementIds) {
    const element = elements[elementId];

    // element might be undefined in case of `elementId` being invalid
    if (!element || isNodeToHide(element, elements)) continue;

    const filterResult = sheetsFilter(element, parentElement.type);

    let children = filterResult.shouldKeepChildren
      ? generateProjectTree(element.id, elements, areaDataSets, true)
      : [];
    if (element.type === IElementType.timeSeries) {
      children.sort((a, b) => {
        assert(
          a.element && b.element,
          "Node children of a timeseries should be linked to an IElement",
        );
        return newestToOldest(a.element, b.element);
      });
    }

    // Sections(room) inside a group should not be visible if they're empty
    // But we need to allow Sections(room) inside a timeSeries
    // as they represent a single 360 capture and are leaf node of the tree
    if (shouldHideIfEmpty(element, parentElement)) {
      filterResult.shouldKeepElement &&= children.length > 0;
    }

    if (filterResult.shouldKeepElement) {
      const { id, name, createdAt, type, typeHint } = element;

      // Move point clouds to front
      const [pcs, rest] = partition(
        children,
        (node) => node.element?.typeHint === IElementTypeHint.dataSession,
      );
      children = [...pcs, ...rest];

      subtree.push({
        id,
        label: filterResult.displayName ?? name,
        element: {
          id,
          type,
          typeHint,
          createdAt,
        },
        directParent: {
          type: parentElement.type,
          typeHint: parentElement.typeHint,
        },
        children: children.length > 0 ? children : null,
      });
    } else if (filterResult.shouldKeepChildren) {
      subtree.push(...children);
    }
  }

  return subtree;
}

/**
 * A decision about whether to render an iElement in the 2D tree.
 *
 * Additionally allows to adjust the string and icon used for rendering this element.
 */
interface ITreeRenderDecision {
  /**
   * Should the element itself be rendered in the tree?
   *
   * This does _not_ influence the rendering of the element's children.
   */
  shouldKeepElement: boolean;

  /**
   * Should the element's children be included in the tree?
   *
   * Whether the children are _rendered_ in the tree is decided
   * again with this function.
   * However, if this is `false`, all children will be discarded.
   */
  shouldKeepChildren: boolean;

  /** A custom display name to use for a node */
  displayName?: string;
}

/**
 * @returns Shows a flat list of all Locations with timestamps as their children.
 * @param iElement IElement to use as base for the filtering
 * @param parentType Parent type of the given IElement
 */
function locationFilter(
  iElement: IElement,
  parentType?: string,
): ITreeRenderDecision {
  if (iElement.type === IElementType.section) {
    if (parentType === IElementType.timeSeries) {
      let displayName = convertToDateString(iElement.createdAt);

      if (iElement.typeHint === IElementTypeHint.dataSession) {
        displayName += ` - ${iElement.name}`;
      }

      const videoModeCopy = isIElementVideoModeCopy(iElement);

      return {
        // Stop recursive rendering of tree at this level
        shouldKeepChildren: false,

        shouldKeepElement: !videoModeCopy,

        displayName,
      };
    }

    return {
      shouldKeepElement: true,
      shouldKeepChildren: true,
    };
  }

  // Parent of all the 360 Images
  if (
    iElement.type === IElementType.group &&
    iElement.typeHint === IElementTypeHint.rooms &&
    iElement.childrenIds?.length
  ) {
    return {
      shouldKeepElement: true,
      shouldKeepChildren: true,
      displayName: "360° Photos",
    };
  }

  // Don't show the TimeSeries if it has no children
  if (
    isIElementTimeseriesDataSession(iElement) &&
    iElement.childrenIds?.length
  ) {
    return {
      shouldKeepElement: true,
      shouldKeepChildren: true,
      displayName: "4D Sessions",
    };
  }

  // Ignore other elements, but keep their children
  return {
    shouldKeepElement: false,
    shouldKeepChildren: true,
  };
}

/**
 * @returns a list of elements to be shown under video recordings.
 * @param iElement IElement to use as base for the filtering
 */
function videoRecordingsFilter(iElement: IElement): ITreeRenderDecision {
  if (
    iElement.type === IElementType.section &&
    iElement.typeHint === IElementTypeHint.odometryPath
  ) {
    // Use the creation date of the ancestor while displaying
    const displayNameWithDate = `${convertToDateTimeString(
      iElement.createdAt,
    )} - ${iElement.name}`;

    return {
      shouldKeepElement: true,
      shouldKeepChildren: true,
      displayName: displayNameWithDate,
    };
  } else if (
    iElement.type === IElementType.timeSeries &&
    iElement.typeHint === IElementTypeHint.videoRecordings
  ) {
    // Parent of all video recordings, hence keep the element
    return {
      shouldKeepElement: true,
      shouldKeepChildren: true,
      displayName: "Video recordings",
    };
  } else if (
    iElement.type === IElementType.section &&
    iElement.typeHint === IElementTypeHint.videoRecordings
  ) {
    // Use the creation date of the element while displaying
    const displayNameWithDate = `${convertToDateTimeString(
      iElement.createdAt,
    )} - ${iElement.name}`;

    return {
      shouldKeepElement: true,
      shouldKeepChildren: false,
      displayName: displayNameWithDate,
    };
  }
  // Ignore other elements, but keep their children
  return {
    shouldKeepElement: false,
    shouldKeepChildren: true,
  };
}

/**
 * @returns true if the typeHint is a videoRecording or an odometryPath
 * @param typeHint it's the typeHint of the iElement to check
 */
function isVideoRecordingOrOdometryPath(
  typeHint: IElementBase["typeHint"],
): boolean {
  return (
    typeHint === IElementTypeHint.videoRecordings ||
    typeHint === IElementTypeHint.videoRecordingTrack ||
    typeHint === IElementTypeHint.odometryPath
  );
}

/**
 * @returns Shows tree of sheets at the top level and the locations being their children
 *    Also, display the timeseries elements of the locations
 * @param iElement IElement to use as base for the filtering
 * @param parentType Parent type of the given iElement
 */
export function sheetsFilter(
  iElement: IElement,
  parentType?: string,
): ITreeRenderDecision {
  if (iElement.type === IElementType.section) {
    const { typeHint } = iElement;

    if (typeHint === IElementTypeHint.area) {
      // Keep the parent of the floor plan pdfs folder
      return {
        shouldKeepElement: true,
        shouldKeepChildren: true,
      };
    }

    if (typeHint === IElementTypeHint.bimModel) {
      // do not display CAD's in Capture tree
      return {
        shouldKeepElement: false,
        shouldKeepChildren: false,
      };
    }

    if (typeHint === IElementTypeHint.cluster) {
      // Do not show the clusters in the project tree
      return {
        shouldKeepElement: false,
        shouldKeepChildren: true,
      };
    }
  }

  if (isVideoRecordingOrOdometryPath(iElement.typeHint)) {
    return videoRecordingsFilter(iElement);
  }

  return locationFilter(iElement, parentType);
}

/**
 * Finds a tree item with the specified ID in a tree data structure.
 *
 * @param id - The ID of the tree item to find.
 * @param tree - The tree data structure to search in.
 * @returns The tree item if found, otherwise undefined.
 */
export function findTreeItem(id: GUID, tree: TreeData[]): TreeData | undefined {
  return walkWithQueue([...tree], (item, append) => {
    if (item.id === id) return item;
    if (item.children) append(...item.children);
  });
}

/**
 * @returns The tree representation of the whole project without any filtering or ordering logic
 * @param parentId ID of the parent of the current recursion level
 * @param elements All IElements of the project
 */
function generateUnfilteredTree(
  parentId: GUID,
  elements: IElementsRecord,
): TreeData[] {
  const subtree: TreeData[] = [];

  const parentElement = elements[parentId];
  if (!parentElement) return subtree;

  // The iElements of the current level of recursion
  const levelElementIds = parentElement.childrenIds ?? [];

  for (const elementId of levelElementIds) {
    const element = elements[elementId];

    // element might be undefined in case of `elementId` being invalid
    if (!element) continue;

    const { id, name, type, createdAt, typeHint } = element;
    const children = generateUnfilteredTree(id, elements);

    subtree.push({
      id,
      label: `${name} - ${type}(${typeHint}) - ${id}`,
      element: {
        id,
        type,
        typeHint,
        createdAt,
      },
      directParent: {
        type: parentElement.type,
        typeHint: parentElement.typeHint,
      },
      children: children.length > 0 ? children : null,
    });
  }

  return subtree;
}

/**
 * Append a fake 4dSession folder with all the datasets in an area that are part of the capture tree
 *
 * If the node is not an area or does not have dataset inside no new node is created
 *
 * @param node to check for datasets in the capture tree
 * @param areaDataSets mapping between areas and capture tree datasets
 * @param elements in the project
 * @param subtree of the current node to append the new folder
 */
function createArea4dSessionNodeIfNeeded(
  node: IElement,
  areaDataSets: Record<GUID, DataSetAreaInfo[] | undefined>,
  elements: IElementsRecord,
  subtree: TreeData[],
): void {
  const dataSets = areaDataSets[node.id];

  // Node is not an area or it does not contain any datasets
  if (!dataSets) return;

  const children: TreeData[] = [];

  for (const dataSet of dataSets) {
    const dataSetElement = elements[dataSet.elementId];

    // DataSets from the capture tree are not wrapped in a data session
    // TODO: Remove when all projects will be migrated to the capture tree (https://faro01.atlassian.net/browse/SWEB-4468)
    const isFromCaptureTree =
      dataSetElement?.parentId &&
      elements[dataSetElement.parentId]?.typeHint !==
        IElementTypeHint.dataSession;

    // The dataset is not loaded yet
    if (!dataSetElement || !isFromCaptureTree) continue;

    children.push({
      id: dataSetElement.id,
      label: `${convertToDateString(dataSetElement.createdAt)} - ${
        dataSetElement.name
      }`,
      element: {
        id: dataSetElement.id,
        type: dataSetElement.type,
        typeHint: dataSetElement.typeHint,
        createdAt: dataSetElement.createdAt,
      },
      children: null,
      directParent: {
        type: IElementType.section,
        typeHint: IElementTypeHint.dataSets,
      },
    });
  }

  // No dataset for this area is loaded
  if (!children.length) return;

  subtree.push({
    id: `${node.id}-4dsessions`,
    label: "4D Sessions",
    children,
  });
}
