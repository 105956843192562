import { useEffect } from "react";
import { Analytics } from "./analytics-service";
import { ISetPropsParams } from "./analytics-types";

/**
 * Initialize the analytics client for the application unless the user is from either HoloBuilder or Faro
 *
 * @param key amplitude key
 * @param currentUserEmail current user email if an user is logged in
 * @param setProps user properties that should be attached to every tracking event
 */
export function useAnalyticsInitialization(
  key?: string,
  currentUserEmail?: string,
  setProps?: ISetPropsParams,
): void {
  useEffect(() => {
    // Exclude @faro.com and @holobuilder.com and anonymous users from the analytics
    // we can't track anonymous users without a consent dialog that we're not implementing
    if (!key || isInternalUserEmail(currentUserEmail)) {
      return;
    }
    Analytics.init(key);
    if (setProps !== undefined) {
      Analytics.props = { ...setProps };
    }
  }, [currentUserEmail, key, setProps]);
}

/**
 * check if user email is from @faro.com or @holobuilder.com or anonymous
 *
 * @param userEmail the given user email
 * @returns true if the given usr email is from @faro.com or @holobuilder.com or anonymous, false otherwise
 */
export function isInternalUserEmail(userEmail?: string): boolean {
  return (
    !userEmail ||
    // userEmail.includes("@faro.com") ||
    userEmail.includes("@holobuilder.com")
  );
}
