import { Box, Checkbox, Divider, Stack } from "@mui/material";
import {
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { FaroButton } from "../button/faro-button";
import { neutral } from "../colors";
import { Option } from "../dropdown/dropdown-types";
import { TextField } from "../input/text-field";
import { FaroText } from "../text/faro-text/faro-text";
import { TruncatedFaroText } from "../text/truncated-text";
import { DARK_COLORS, LIGHT_COLORS } from "./filter-menu-colors";

/** Type of the option in filter menu */
export type FilterMenuOptionType = Omit<Option, "isDisabled" | "isHeader">;

/** The props of the menu content */
export type FilterMenuContentProps = {
  /** Label to use as the list heading */
  headingLabel?: ReactNode;

  /** Options to be shown in the filter menu */
  options: FilterMenuOptionType[];

  /** Callback when the filter is being applied with a list of options that has been selected */
  onFilterChange?(
    selectedOptions: FilterMenuOptionType[],
    enabledOption: boolean,
  ): void;

  /** Callback for when the "clear all" button is clicked */
  onResetFilter?(): void;

  /**
   * The options in the menu marked as selector. If undefined, the component is uncontrolled,
   * otherwise, the selectedOptions property should be managed outside the component
   */
  selectedOptions?: FilterMenuOptionType[];

  /** The optional elements placed right after the search bar and before the options list */
  children?: ReactNode | undefined;

  /** true to use the dark variant @default false */
  dark?: boolean;
};

/**
 * @returns The content of a filter menu
 */
export function FilterMenuContent({
  headingLabel,
  options,
  selectedOptions: selectedOptionsExternal,
  onFilterChange,
  onResetFilter,
  dark = false,
  children,
}: FilterMenuContentProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  const [searchText, setSearchText] = useState("");

  const filteredOptions = useMemo(
    () =>
      options.filter((o) =>
        o.value.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [searchText, options],
  );

  const updateSearchText = useCallback((newInputValue: string) => {
    setSearchText(newInputValue);
  }, []);

  const [selectedOptions, setSelectedOptions] = useSelectedOptions(
    selectedOptionsExternal,
  );

  const updateSelectedOptions = useCallback(
    (selectedOptions: FilterMenuOptionType[], enabledOption: boolean) => {
      setSelectedOptions?.(selectedOptions);
      onFilterChange?.(selectedOptions, enabledOption);
    },
    [onFilterChange, setSelectedOptions],
  );

  const resetSelectedOptionsAndSearchText = useCallback(() => {
    setSearchText("");
    setSelectedOptions?.([]);
    onResetFilter?.();
  }, [onResetFilter, setSelectedOptions]);

  return (
    <Stack
      flexGrow="1"
      direction="column"
      p={0.5}
      sx={{ color: colors.color, maxWidth: "240px" }}
    >
      {headingLabel && (
        <FaroText variant="labelS" color="inherit" textTransform="uppercase">
          {headingLabel}
        </FaroText>
      )}
      <TextField
        text={searchText}
        onTextChanged={updateSearchText}
        placeholder="Search"
        sx={{ height: 38, mt: 2, py: 0.5 }}
        dark={dark}
        fullWidth
      />
      {children}
      {filteredOptions.length > 0 && (
        <Box
          component="div"
          sx={{ maxHeight: "370px", overflowY: "auto", py: 1 }}
        >
          {filteredOptions.map((option) => (
            <FilterMenuOption
              option={option}
              selected={!!selectedOptions.find((o) => o.key === option.key)}
              onCheckboxChanged={(checked) => {
                if (checked) {
                  updateSelectedOptions([...selectedOptions, option], true);
                } else {
                  updateSelectedOptions(
                    selectedOptions.filter((o) => o.key !== option.key),
                    false,
                  );
                }
              }}
              dark={dark}
              key={option.key}
            />
          ))}
        </Box>
      )}
      {filteredOptions.length === 0 && (
        <FaroText
          variant="bodyL"
          color="inherit"
          sx={{
            px: 2,
            py: 1.75,
          }}
        >
          No results found
        </FaroText>
      )}
      <Divider sx={{ bgcolor: neutral[0], opacity: 0.2 }} />
      <Box component="div">
        <FaroButton
          variant="ghost"
          dark={dark}
          onClick={resetSelectedOptionsAndSearchText}
        >
          Clear all
        </FaroButton>
      </Box>
    </Stack>
  );
}

/** The callback used to set the selected options */
type SelectedOptionsCallback = (options: FilterMenuOptionType[]) => void;

/** The type returned by the useSelectedOptions */
type SelectedOptionsType = [
  FilterMenuOptionType[],
  SelectedOptionsCallback | undefined,
];

/**
 * Defines the selected options property and its setter.
 * If the property is passed by the consumer of the component, the setter will be undefined
 * and the consumer will need to manage the property outside (controlled state).
 * If the property is not defined, the component will manage it internally using a useState
 * (uncontrolled state).
 *
 * @param selectedOptionsExternal The selected options defined externally, if defined
 * @returns The selected options property and the setter, if defined
 */
function useSelectedOptions(
  selectedOptionsExternal: FilterMenuOptionType[] | undefined,
): SelectedOptionsType {
  const [selectedOptionsInternal, setSelectedOptionsInternal] = useState<
    FilterMenuOptionType[]
  >([]);

  // If the selectedOptions have been defined from outside, than the value should be managed outside (controlled state)
  // If the selectedOptions have not been defined, they are managed internally (uncontrolled state)
  const selectedOptions = selectedOptionsExternal ?? selectedOptionsInternal;
  const setSelectedOptions = selectedOptionsExternal
    ? undefined
    : setSelectedOptionsInternal;

  return [selectedOptions, setSelectedOptions];
}

interface FilterMenuOptionProps extends HTMLAttributes<HTMLLIElement> {
  /** Option to be displayed */
  option: FilterMenuOptionType;

  /** True if the option is selected */
  selected: boolean;

  /** True if the current style mode is dark */
  dark: boolean;

  /** Callback executed when the user interacts with the checkbox */
  onCheckboxChanged(checked: boolean): void;
}

function FilterMenuOption({
  option,
  selected,
  dark,
  onCheckboxChanged,
  ...rest
}: FilterMenuOptionProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;
  return (
    <Stack
      component="li"
      direction="row"
      gap={1}
      sx={{ mb: 1.5, cursor: "pointer" }}
      alignItems="center"
      onClick={() => onCheckboxChanged(!selected)}
      {...rest}
    >
      {/* The design of options and checkbox are covered here - https://faro01.atlassian.net/browse/SWEB-4423 */}
      <Checkbox
        sx={{
          color: "inherit",
          p: 0,
          my: 0.5,
          "& .MuiSvgIcon-root": {
            fontSize: 20,
          },
          "&.Mui-checked": {
            "& .MuiSvgIcon-root": {
              color: colors.checkBoxColor,
            },
          },
        }}
        checked={selected}
        onChange={(ev) => onCheckboxChanged(ev.target.checked)}
      />
      <TruncatedFaroText
        variant="bodyM"
        color="inherit"
        tooltip={option.tooltip}
      >
        {option.label}
      </TruncatedFaroText>
    </Stack>
  );
}
